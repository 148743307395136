<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div
            class="container mx-auto my-12 h-auto flex flex-wrap justify-between items-start text-sm p-8 pt-8 pb-4 box-border border-4 border-gray-400 bg-gray-100"
          >
            <a class="text-2xl text-center text-black font-bold"
              >Solicitação : Alterar Entrada (Compra) : {{ situacao }}
            </a>
            <RouterLinkButton
              btnText=""
              color="sky"
              tipo="fas"
              icone="arrow-left"
              url="/solicitacao"
            />
            <div class="bg-green-500 w-full h-1"></div>
            <div class="mx-auto py-4">
              <div class="flex flex-wrap font-bold text-gray-100">
                <div class="w-full mt-4">
                  <div class="text-gray-900 text-xl">
                    Código : {{ id_solicitacao }}
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <TextInput
              label=""
              placeholder=""
              v-model:input="id_empresa"
              inputType="hidden"
              readonly
            />
            <a class="text-sky-400 font-bold"
              >Empresa : {{ id_empresa }}-{{ razaoSocial_empresa }}</a
            >
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModal()"
              >
                Escolha a empresa que pertence a entrada !
              </button>
              <div
                v-if="showModal"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Empresa</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModal()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome da empresa"
                            placeholder="Pesquisar..."
                            v-model:input="searchCompany"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getCompanyList"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Empresa</th>
                                <th class="border px-4 py-2"></th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listCompany in listCompanies"
                                :key="listCompany"
                              >
                                <td>{{ listCompany.id }}</td>
                                <td>{{ listCompany.razaoSocial }}</td>
                                <td>{{ listCompany.nomeFantasia }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectCompany(listCompany)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageCompanies"
                              :pages="pageCountCompanies"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listCompanies"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-sky-500 bg-transparent border border-solid border-sky-500 hover:bg-sky-500 hover:text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModal()"
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModal"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <TextInput
              label=""
              placeholder=""
              v-model:input="id_fornecedor"
              inputType="hidden"
              readonly
            />
            <a class="text-sky-400 font-bold"
              >Fornecedor : {{ id_fornecedor }}-{{ razaoSocial_fornecedor }}</a
            >
            <div>
              <button
                class="bg-gray-400 text-white active:bg-pink-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                type="button"
                v-on:click="toggleModalSupplier()"
              >
                Escolha o fornecedor que pertence a entrada !
              </button>
              <div
                v-if="showModalSupplier"
                class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex"
              >
                <div class="relative w-auto my-6 mx-auto max-w-3xl">
                  <!--content-->
                  <div
                    class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                  >
                    <!--header-->
                    <div
                      class="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t"
                    >
                      <h3 class="text-3xl font-semibold">Fornecedor</h3>
                      <button
                        class="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        v-on:click="toggleModalSupplier()"
                      >
                        <span
                          class="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none"
                        >
                          ×
                        </span>
                      </button>
                    </div>
                    <!--body-->
                    <div class="relative p-6 flex-auto">
                      <div class="flex mt-4 mb-8">
                        <div class="w-full md:w-1/2 px-3">
                          <TextInput
                            label="Digitar o nome do fornecedor"
                            placeholder="Pesquisar..."
                            v-model:input="searchSupplier"
                            inputType="text"
                          />
                        </div>
                        <div class="w-full md:w-1/2 px-3">
                          <form
                            @submit.prevent="onSubmit"
                            @click="getSupplierList"
                          >
                            <LoadingButton
                              :isLoading="isLoading"
                              class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                            >
                              Pesquisar
                            </LoadingButton>
                          </form>
                        </div>
                        <div class="mx-auto py-4">
                          <table class="table-auto text-xs">
                            <thead>
                              <tr class="text-gray-600 bg-gray-300">
                                <th class="border px-4 py-2">Id</th>
                                <th class="border px-4 py-2">Fornecedor</th>
                                <th class="border px-4 py-2">Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                class="text-gray-500 bg-gray-100"
                                v-for="listSupplier in listSuppliers"
                                :key="listSupplier"
                              >
                                <td>{{ listSupplier.id }}</td>
                                <td>{{ listSupplier.razaoSocial }}</td>
                                <td>
                                  <div
                                    class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                                  >
                                    <SubmitFormButton
                                      btnText="Escolha"
                                      @click="selectSupplier(listSupplier)"
                                    />
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          <div class="flex items-center justify-center p-2">
                            <v-pagination
                              v-model="pageSuppliers"
                              :pages="pageCountSuppliers"
                              :range-size="1"
                              active-color="#337aff"
                              @update:modelValue="listSuppliers"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-snotify />
                    <!--footer-->
                    <div
                      class="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b"
                    >
                      <button
                        class="text-sky-500 bg-transparent border border-solid border-sky-500 hover:bg-sky-500 hover:text-white active:bg-sky-600 font-bold uppercase text-sm px-6 py-3 rounded outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        v-on:click="toggleModalSupplier()"
                      >
                        Voltar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="showModalSupplier"
                class="opacity-25 fixed inset-0 z-40 bg-black"
              ></div>
            </div>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="w-full md:w-full px-3">
              <div class="flex-auto px-4 lg:px-10 py-10 pt-0">
                <div
                  class="w-full border px-4 py-2 rounded focus:border-blue-500 focus:shadow-outline outline-none"
                >
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputDate
                      label="Data emissão"
                      placeholder="Digitar emissão !"
                      v-model:input="dt_compracupom"
                      inputType="text"
                    />
                  </div>
                  <div class="w-full text-gray-900 md:w-full px-6">
                    <TextInputMoeda
                      label="Vlr Solicitação"
                      placeholder="Valor total"
                      v-model:input="this.vlrtotalPedido"
                      inputType="hidden"
                      readonly
                    />
                    {{ formatMoeda(this.vlrtotalPedido) }}
                  </div>
                  <a class="text-2xl text-center text-black font-bold">
                    FORMA DE PAGAMENTO
                  </a>
                  <div class="grid grid-cols-4 gap-4">
                    <div>
                      <div class="w-full text-gray-700 md:w-auto px-3">
                        <TextInputMoeda
                          label="Vlr a vista"
                          placeholder="Vlr a vista"
                          v-model:input="vlrTotalavista"
                          inputType="hidden"
                          readonly
                        />
                        {{ formatMoeda(vlrTotalavista) }}
                      </div>
                    </div>
                    <div>
                      <div class="w-full text-gray-700 md:w-auto px-3">
                        <TextInputMoeda
                          label="Vlr a prazo"
                          placeholder="Vlr a prazo"
                          v-model:input="vlrTotalaprazo"
                          inputType="hidden"
                          reandonly
                        />
                        {{ formatMoeda(vlrTotalaprazo) }}
                      </div>
                    </div>
                    <div>
                      <div class="w-full text-gray-700 md:w-auto px-3">
                        <TextInputMoeda
                          label="Vlr débito"
                          placeholder="Vlr débito"
                          v-model:input="vlrTotaldebito"
                          inputType="hidden"
                          reandonly
                        />
                        {{ formatMoeda(vlrTotaldebito) }}
                      </div>
                    </div>
                    <div>
                      <div class="w-full text-gray-700 md:w-auto px-3">
                        <TextInputMoeda
                          label="Vlr crédito"
                          placeholder="Vlr crédito"
                          v-model:input="vlrTotalcredito"
                          inputType="hidden"
                          reandonly
                        />
                        {{ formatMoeda(vlrTotalcredito) }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="situacao !== 'CANCELADO'"
              class="flex flex-wrap mt-8 mb-6"
            >
              <div class="bg-green-500 w-full h-1"></div>
              <form @submit.prevent="onSubmit" @click="update">
                <LoadingButton
                  :isLoading="isLoading"
                  class="block w-full bg-green-500 text-white rounded-sm py-3 text-sm tracking-wide transform motion-safe:hover:-translate-y-1 text-blue-700 motion-safe:hover:scale-110 transition ease-in-out"
                >
                  Alterar
                </LoadingButton>
              </form>
              <vue-snotify />
            </div>

            <div class="bg-green-500 w-full h-1"></div>
            <div class="text-gray-600 text-xl">Itens da solicitação</div>
            <div class="bg-green-500 w-full h-1"></div>
            <table class="table-auto text-xs">
              <thead>
                <tr class="text-gray-600 bg-gray-300">
                  <th class="border px-4 py-2">ID</th>
                  <th class="border px-4 py-2">Código barras</th>
                  <th class="border px-4 py-2">Descrição</th>
                  <th class="border px-4 py-2">Quantidade</th>
                  <th class="border px-4 py-2">Vlr unitario</th>
                  <th class="border px-4 py-2">Vlr total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  class="text-gray-500 bg-gray-100"
                  v-for="listSolicitItem in listSolicitItems"
                  :key="listSolicitItem"
                >
                  <td>{{ listSolicitItem.id }}</td>
                  <td>{{ listSolicitItem.codigo_barra }}</td>
                  <td>{{ listSolicitItem.nome_produto }}</td>
                  <td>{{ listSolicitItem.quantidade }}</td>
                  <td>{{ formatMoeda(listSolicitItem.valor_unitario) }}</td>
                  <td>{{ formatMoeda(listSolicitItem.valor_totalProduto) }}</td>
                  <td>
                    <!-- <SubmitFormButton
                      btnText="Deletar"
                      class="underline text-red-500 hover:text-red-600"
                      @click="deleteItemSolitacao(listSolicitItem.id)"
                    /> -->
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="bg-green-500 w-full h-1"></div>
            <div class="flex items-center justify-center p-2">
              <v-pagination
                v-model="pageItems"
                :pages="pageCountItems"
                :range-size="1"
                active-color="#337aff"
                @update:modelValue="getSolicitItemList"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { ref } from "vue";
import NavbarComponent from "@/components/Navbar.vue";
import SidebarComponent from "@/components/Sidebar.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";
import TextInput from "@/components/global/TextInput.vue";
import TextInputDate from "@/components/global/TextInputDate.vue";
import TextInputMoeda from "@/components/global/TextInputMoeda.vue";
import LoadingButton from "@/components/global/LoadingButton.vue";
import SubmitFormButton from "@/components/global/SubmitFormButton.vue";
import { useRoute, useRouter } from "vue-router";
import { useUserStore } from "@/store/user-store";
import { useSupplierStore } from "@/store/solicitationsupplier-store";

export default {
  components: {
    NavbarComponent,
    SidebarComponent,
    AdmMenuComponent,
    RouterLinkButton,
    LoadingButton,
    TextInput,
    SubmitFormButton,
    TextInputDate,
    TextInputMoeda,
  },
  name: "regular-modal",
  data() {
    const userStore = useUserStore();
    const SupplierStore = useSupplierStore();
    const route = useRoute();
    const router = useRouter();

    const url = process.env.VUE_APP_API_URL;
    let hover = ref(false);
    let showModal = ref(false);
    let showModalSupplier = ref(false);
    let showModalProduct = ref(false);
    let showModalProductCB = ref(false);

    let searchCompany = ref(null);
    let listCompanies = ref(null);
    let pageCompanies = ref(1);
    let pageCountCompanies = ref(null);

    let errors = ref([]);

    return {
      date: new Date().getFullYear(),
      useradministrador: userStore.administrador,
      userid_company: userStore.id_empresa,
      id_responsavel: userStore.id,
      name_responsavel: userStore.full_name,
      SupplierStore: SupplierStore,
      route: route,
      router: router,
      url_api: url,
      hover: hover,
      showModal: showModal,
      showModalSupplier: showModalSupplier,
      showModalProduct: showModalProduct,
      showModalProductCB: showModalProductCB,
      searchCompany: searchCompany,
      listCompanies: listCompanies,
      pageCompanies: pageCompanies,
      pageCountCompanies: pageCountCompanies,
      listStocks: ref(null),
      searchStock: ref(null),
      searchStockCB: ref(null),
      pageStocks: ref(1),
      pageCountStocks: ref(null),
      searchSupplier: ref(null),
      listSuppliers: ref(null),
      pageSuppliers: ref(1),
      pageCountSuppliers: ref(null),
      listSolicitItems: ref(null),
      pageItems: ref(1),
      pageCountItems: ref(null),
      errors: errors,
      error: errors,
      id_empresa: ref(null),
      isLoading: ref(false),
      id_solicitacao: ref(null),
      razaoSocial: ref(null),
      id_empresa: ref(null),
      id_fornecedor: ref(null),
      dt_compracupom: ref(null),
      dt_retirada: ref(null),
      dt_entrega: ref(null),
      valorTotalPedido: ref(null),
      vlrtotal_item: ref(null),
      quantidade: ref(null),
      vlrunitario: ref(null),
      modelo: ref("pedidocompra"),
      situacao: ref(null),
      vlrTotalavista: ref(0),
      vlrTotalaprazo: ref(0),
      vlrTotaldebito: ref(0),
      vlrTotalcredito: ref(0),
    };
  },
  methods: {
    async getSolicitationsupplier() {
      // Make a GET request to the API
      const response = await this.SupplierStore.fetchByIdSolicitationsupplier(
        this.route.params.id
      );
      //console.log(response);
      this.id_solicitacao = this.SupplierStore.id || null;
      this.id_empresa = this.SupplierStore.id_empresa || null;
      this.razaoSocial_empresa = this.SupplierStore.razaoSocial_empresa || null;
      this.id_fornecedor = this.SupplierStore.id_fornecedor || null;
      this.razaoSocial_fornecedor =
        this.SupplierStore.razaoSocial_fornecedor || null;
      this.dt_compracupom = this.SupplierStore.dt_emissao || null;
      // this.dt_retirada = this.SupplierStore.dt_retirada || null;
      // this.dt_entrega = this.SupplierStore.dt_entrega || null;
      this.valorTotalPedido = this.SupplierStore.valorTotalPedido || null;
      this.situacao = this.SupplierStore.situacao || null;
      this.vlrTotalavista = this.SupplierStore.vlrTotalavista || null;
      this.vlrTotalaprazo = this.SupplierStore.vlrTotalaprazo || null;
      this.vlrTotaldebito = this.SupplierStore.vlrTotaldebito || null;
      this.vlrTotalcredito = this.SupplierStore.vlrTotalcredito || null;

      this.$snotify.info("Aguarde atualizando ... !");
    },
    getCompanyList() {
      // Make a GET request to the API
      axios
        .get(
          this.url_api +
            "/api/companysearch/" +
            this.searchCompany +
            "?page=" +
            this.pageCompanies
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountCompanies = response.data.page_count1;
          this.listCompanies = response.data.paginate1.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getSupplierList() {
      axios
        .get(
          this.url_api +
            "/api/suppliersearch/" +
            this.searchSupplier +
            "?page=" +
            this.pageSuppliers
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountSuppliers = response.data.page_count1;
          this.listSuppliers = response.data.paginate1.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getStockListProd() {
      axios
        .get(
          this.url_api +
            "/api/stocksearch/" +
            this.id_fornecedor +
            "/" +
            this.searchStock +
            "?page=" +
            this.pageStocks
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountStocks = response.data.page_count;
          this.listStocks = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getStockListProdCB() {
      axios
        .get(
          this.url_api +
            "/api/stocksearchCB/" +
            this.id_fornecedor +
            "/" +
            this.searchStockCB +
            "?page=" +
            this.pageStocks
        )
        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountStocks = response.data.page_count;
          this.listStocks = response.data.paginate.data;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    getSolicitItemList() {
      axios
        .get(
          this.url_api +
            "/api/salesoitementrylist/" +
            this.route.params.id +
            "?page=" +
            this.pageItems
        )

        // console.log(resItem)

        .then((response) => {
          // Handle the successful response
          // console.log(response.data);
          this.pageCountItems = response.data.page_countItem;
          this.listSolicitItems = response.data.paginateItem.data;
          this.vlrtotalPedido = response.data.valorTotalPedido;
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique novamente !");
        });
    },
    toggleModal() {
      this.showModal = !this.showModal;
    },
    toggleModalSupplier() {
      this.showModalSupplier = !this.showModalSupplier;
    },
    toggleModalProduct() {
      this.showModalProduct = !this.showModalProduct;
    },
    toggleModalProductCB() {
      this.showModalProductCB = !this.showModalProductCB;
    },
    selectCompany(event) {
      this.id_empresa = event.id;
      this.razaoSocial_empresa = event.razaoSocial;
      this.$snotify.warning("Escolheu a : " + this.razaoSocial_empresa);
    },
    formatMoeda(moedaString) {
      const atual = moedaString;

      const valorFormatado = Intl.NumberFormat("pt-br", {
        style: "currency",
        currency: "BRL",
      }).format(atual);

      return valorFormatado;
    },
    selectSupplier(event) {
      this.id_fornecedor = event.id;
      this.razaoSocial_fornecedor = event.razaoSocial;
      this.$snotify.warning("Escolheu a : " + this.razaoSocial_fornecedor);
    },
    selectProduct(event) {
      this.id_produto = event.id;
      this.nome_produto = event.nome_produto;
      this.codigo_barra = event.codigo_barra;
      this.descricao = event.nome_produto;
      this.unidade = event.unidade;
      this.vlrunitario = event.preco_venda;

      this.$snotify.warning("Escolheu a : " + this.nome_produto);
    },
    onSubmit() {
      this.isLoading = true;
      setTimeout(() => (this.isLoading = false), 1000);
    },
    multiplicarprod() {
      // alert(`Selecionou !`)
      this.vlrtotal_item = this.quantidade * this.vlrunitario;
    },
    registerItemSolicitacao() {
      if (this.id_solicitacao == null) {
        this.$snotify.warning("Register solicitation (Registrar solicitação)");
      }

      if (this.dt_compracupom == null) {
        this.$snotify.warning("Enter request date(Digitar data de emissão)");
      }

      if (this.codigo_barra == null) {
        this.$snotify.warning("Enter request product(Incluir produto)");
      }

      this.error = [];
      let data = new FormData();
      data.append("id_solicitacao", this.id_solicitacao || "");
      data.append("id_produto", this.id_produto || "");
      data.append("descricao", this.descricao || "");
      data.append("dt_compracupom", this.dt_compracupom || "");
      data.append("unidade", this.unidade || "");
      data.append("vlrunitario", this.vlrunitario || "");
      data.append("quantidade", this.quantidade || "");
      data.append("vlrtotal_item", this.vlrtotal_item || "");

      axios
        .post(this.url_api + "/api/salesoitementry", {
          id_pedido_item: this.id_solicitacao,
          id_prod_item: this.id_produto,
          codigo_barra: this.codigo_barra,
          nome_produto: this.descricao,
          dt_compracupom: this.dt_compracupom,
          unidade: this.unidade,
          valor_unitario: this.vlrunitario,
          quantidade: this.quantidade,
          valor_totalProduto: this.vlrtotal_item,
        })

        .then(() => {
          // Handle the successful response
          // console.log(response.data);
          this.$snotify.success(
            "Product successfully included in the request(Produto incluido com sucesso na solicitação)"
          );
          this.getSolicitItemList();
        })
        .catch((error) => {
          // Handle any errors
          console.error("Error fetching data:", error);
          this.$snotify.error("Verifique, falta informação !");
        });
    },
    update() {
      // console.log('Dentro update')

      this.error = [];
      let data = new FormData();
      data.append("id_responsavel", this.id_responsavel || "");
      data.append("id_empresa", this.id_empresa || "");
      data.append("id_fornecedor", this.id_fornecedor || "");
      data.append("dt_compracupom", this.dt_compracupom || "");
      // data.append("dt_retirada", this.dt_retirada || "");
      // data.append("dt_entrega", this.dt_entrega || "");
      //data.append("vlrtotal", this.vlrtotal || "");
      data.append("modelo", this.modelo || "");
      // console.log(data)

      axios
        .put(this.url_api + "/api/salesorderentry/" + this.route.params.id, {
          id_responsavel: this.id_responsavel,
          id_empresa: this.id_empresa,
          id_fornecedor: this.id_fornecedor,
          dt_compracupom: this.dt_compracupom,
          // dt_retirada: this.dt_retirada,
          // dt_entrega: this.dt_entrega,
          //valorTotalPedido: this.vlrtotal,
          modelo: this.modelo,
        })
        .then(() => {
          // console.log(teste)
          this.$snotify.success("Salvo com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          // this.errors = error.data.error;
          console.error("Error salesorderentry:", this.error);
          this.$snotify.error("Verificar Empresa, Fornecedor ou as datas !");
          return error;
        });
    },
    deleteItemSolitacao(event) {
      // console.log(event)

      axios
        .delete(this.url_api + "/api/salesoitementry/" + event)
        .then(() => {
          // console.log(teste)
          this.getSolicitItemList();
          this.$snotify.success("Salvo com sucesso !");
        })
        .catch((error) => {
          // Handle any errors
          // this.errors = error.data.error;
          console.error("Error deleteItemSolitacao:", this.error);
          this.$snotify.error("Verificar Item !");
          return error;
        });
    },
    onPageChange() {
      this.getSolicitationsupplier();
      this.getSolicitItemList();
      this.getCompanyList();
      this.getSupplierList();
      this.getStockListProd();
      this.getStockListProdCB();
      this.update();
    },
  },
  mounted() {
    this.getSolicitationsupplier();
    this.getSolicitItemList();
  },
};
</script>
