<template>
  <button @click="$emit('submit, true')">
    {{ btnText }} <fas :icon="['fas', 'check']" />
  </button>
</template>

<script setup>
import { defineProps, toRefs } from "vue";

const props = defineProps({
  btnText: String,
});
const { btnText } = toRefs(props);
</script>
