import axios from "axios";
import { defineStore } from "pinia";

const url = process.env.VUE_APP_API_URL;

let url_api = url;

export const usePlanningsStore = defineStore("plannings", {
  state: () => ({
    id: null,
    id_responsavel: null,
    id_empresa: null,
    razaoSocial: null,
    tarefa: null,
    mes_ano: null,
    aberto: null,
    dt_mes: null,
  }),
  actions: {
    setPlanningIdDetails(res) {
      //Busca do banco de dados
      console.log("setPlanningIdDetails", res.data);
      this.$state.id = res.data.id;
      this.$state.id_responsavel = res.data.id_responsavel;
      this.$state.id_empresa = res.data.id_empresa;
      this.$state.tarefa = res.data.tarefa;
      this.$state.mes_ano = res.data.mes_ano;
      this.$state.aberto = res.data.aberto;
      this.$state.dt_mes = res.data.dt_mes;
    },

    async fetchByIdPlanning(id) {
      let res = await axios.get(url_api + "/api/planning/" + id);
      console.log(res.data);
      this.$state.id = res.data.planning.id;
      this.$state.id_responsavel = res.data.planning.id_responsavel;
      this.$state.id_empresa = res.data.planning.id_empresa;
      if (res.data.planning.company) {
        this.$state.razaoSocial = res.data.planning.company.razaoSocial;
      } else {
        this.$state.razaoSocial = null;
      }
      this.$state.tarefa = res.data.planning.tarefa;
      this.$state.mes_ano = res.data.planning.mes_ano;
      this.$state.aberto = res.data.planning.aberto;
      this.$state.dt_mes = res.data.planning.dt_mes;
    },

    clearIdPlanning() {
      this.$state.id = null;
      this.$state.id_responsavel = null;
      this.$state.id_empresa = null;
      this.$state.razaoSocial = null;
      this.$state.tarefa = null;
      this.$state.mes_ano = null;
      this.$state.aberto = null;
      this.$state.dt_mes = null;
    },
  },
  persist: true,
});
