<template>
  <nav
    class="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-blue-500 flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6"
  >
    <div
      class="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto"
    >
      <!-- Toggler -->
      <button
        class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border"
        type="button"
        v-on:click="toggleCollapseShow('bg-white m-2 py-3 px-6')"
      >
        <fas :icon="['fas', 'bars']" style="font-size: 36px" />
      </button>
      <RouterLinkButton
        btnText="Painel de controle"
        color="sky"
        tipo="fas"
        icone="place-of-worship"
        url="/dashboard"
      />
      <!-- User -->
      <ul class="md:hidden items-center flex flex-wrap list-none">
        <li class="inline-block relative">
          <notification-dropdown-component></notification-dropdown-component>
        </li>
        <li class="inline-block relative">
          <user-dropdown-component></user-dropdown-component>
        </li>
      </ul>
      <!-- Collapse -->
      <div
        class="md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded"
        v-bind:class="collapseShow"
      >
        <!-- Collapse header -->
        <div
          class="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200"
        >
          <div class="flex flex-wrap">
            <div class="w-6/12">
              <a
                class="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                href="javascript:void(0)"
              >
              </a>
            </div>
            <div class="w-6/12 flex justify-end">
              <button
                type="button"
                class="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                v-on:click="toggleCollapseShow('hidden')"
              >
                <fas :icon="['fas', 'circle-left']" style="font-size: 36px" />
              </button>
            </div>
          </div>
        </div>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none">
          <li class="items-left">
            <a
              @click="toggleSubMenu('admin')"
              class="cursor-pointer hover:text-blue-100"
              >ADMINISTRAÇÃO</a
            >
            <ul
              v-show="isSubMenuOpen('admin')"
              class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4"
            >
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Empresas"
                  color="sky"
                  tipo="fas"
                  icone="place-of-worship"
                  url="/admlistaempresa"
                />
              </li>
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Clientes"
                  color="sky"
                  tipo="fas"
                  icone="place-of-worship"
                  url=""
                />
              </li>
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Colaboradores"
                  color="sky"
                  tipo="fas"
                  icone="users"
                  url="/admlistauser"
                />
              </li>
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Fornecedores"
                  color="sky"
                  tipo="fas"
                  icone="truck-front"
                  url="/admlistafornecedor"
                />
              </li>
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Planejamento"
                  color="sky"
                  tipo="fas"
                  icone="screwdriver-wrench"
                  url="/admlistaPlanejamento"
                />
              </li>
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Estoque"
                  color="sky"
                  tipo="fas"
                  icone="boxes-stacked"
                  url="/admlistaproduto"
                />
              </li>
            </ul>
          </li>
          <li class="items-center">
            <a
              @click="toggleSubMenu('financeiro')"
              class="cursor-pointer hover:text-blue-100"
              >FINANCEIRO</a
            >
            <ul
              v-show="isSubMenuOpen('financeiro')"
              class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4"
            >
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Fluxo de caixa"
                  color="sky"
                  tipo="fas"
                  icone="money-bill-transfer"
                  url="/solicitacao"
                />
              </li>
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Contas"
                  color="sky"
                  tipo="fas"
                  icone="place-of-worship"
                  url="/contas"
                />
              </li>
              <li class="inline-flex">
                <RouterLinkButton
                  btnText="Relatórios"
                  color="sky"
                  tipo="fas"
                  icone="truck-front"
                  url="/relatorio"
                />
              </li>
            </ul>
          </li>
        </ul>
        <!-- Divider -->
        <hr class="my-4 md:min-w-full" />
        <!-- Heading -->
        <h6
          class="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline"
        ></h6>
        <!-- Navigation -->
        <ul class="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
          <li class="inline-flex">
            <a
              class="text-blueGray-700 hover:text-blueGray-500 text-sm block mb-4 no-underline font-semibold"
              href=""
            >
            </a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import NotificationDropdownComponent from "./NotificationDropdown.vue";
import UserDropdownComponent from "./UserDropdown.vue";
import AdmMenuComponent from "@/components/parcial/adm/AdmMenu.vue";
import FinMenuComponent from "@/components/parcial/financeiro/FinMenu.vue";
import RouterLinkButton from "@/components/global/RouterLinkButton.vue";

import { useUserStore } from "@/store/user-store";

export default {
  components: {
    NotificationDropdownComponent,
    UserDropdownComponent,
    AdmMenuComponent,
    FinMenuComponent,
    RouterLinkButton,
  },
  data() {
    const userStore = useUserStore();
    const url = process.env.VUE_APP_API_URL;
    return {
      collapseShow: "hidden",
      administrador: userStore.administrador,
      url_api: url,
      isOpen: false,
      openSubMenu: null,
    };
  },
  methods: {
    toggleCollapseShow: function (classes) {
      this.collapseShow = classes;
      this.isOpen = !this.isOpen;
    },
    toggleSidebar() {
      this.isOpen = !this.isOpen;
    },
    toggleSubMenu(menu) {
      this.openSubMenu = this.openSubMenu === menu ? null : menu;
    },
    isSubMenuOpen(menu) {
      return this.openSubMenu === menu;
    },
  },
};
</script>
<style scoped>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: opacity 0.5s, transform 0.5s;
}
.slide-fade-enter, .slide-fade-leave-to /* .slide-fade-leave-active in <2.1.8 */ {
  opacity: 0;
  transform: translateX(-20px);
}
</style>
