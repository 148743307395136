<template>
  <div>
    <sidebar-component></sidebar-component>
    <div class="relative md:ml-64 bg-blueGray-100">
      <navbar-component></navbar-component>
      <!-- Header -->
      <div class="relative bg-blue-600 md:pt-32 pb-32 pt-12">
        <div class="rounded-t mb-0 px-4 py-3 border-0">
          <div
            class="container mx-auto my-12 h-auto flex flex-wrap justify-between items-start text-sm p-8 pt-8 pb-4 box-border border-4 border-gray-400 bg-gray-100"
          >
            <a class="text-2xl text-center text-black font-bold"
              >Admin: Cadastrar planejamento</a
            >
            <RouterLinkButton
              btnText=""
              color="sky"
              tipo="fas"
              icone="arrow-left"
              url="/admlistaPlanejamento"
            />
            <div class="bg-green-500 w-full h-1"></div>
            <a class="text-sky-400 font-bold">EM DESENVOLVIMENTO</a>
            <div class="bg-green-500 w-full h-1"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
